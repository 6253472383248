<template>
  <div>
    <b-row class="mb-2">
        <b-col md="12">
            <export-button :filter="exportFilter"
                           url="/transaction/report-players/export"
                           :disabled="loadindData ||  playerSelectId === null"
                           file-title="Players_Report"/>
        </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{$t("player_brands_report")}}</b-card-title>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFromP"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <!--  <p>{{dateToP}}</p> -->
                <flat-pickr
                  v-model="dateToP"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y H:i',
                    enableTime: true,
                    time_24hr: true,
                    wrap : true,
                    plugins: [
                       new yearDropdownPlugin({
                         yearStart: 100,
                         yearEnd: 0
                      })
                     ],
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Player Name" label-for="username">
                <template #label>{{ $t("labels.name_player") }}</template>
                <v-select
                  @option:selected="selectPlayer"
                  :options="userList"
                  @search="onSearch"
                  :filterable="false"
                  :clearable="false"
                  label="userName"
                  :placeholder="$t('buttons.search')"
                >
                  <template slot="no-options">
                    {{ $t("type_a_letter_to_start_the_search") }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.userName }}
                    </div>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
              <b-col md="4">
                  <b-form-group :label="$t('labels.search_historic')" label-for="isHistorical">
                      <b-form-checkbox v-model="isHistorical" @change="getPlayerData"/>
                  </b-form-group>
              </b-col>
          </b-row>
          <br />
          <b-overlay :show="loadindData">

              <b-table-simple hover small caption-top responsive>
                <b-thead head-variant="light">
                  <b-tr>
                    <b-th>{{ $t("category") }}</b-th>
                    <b-th>{{ $t("bet") }}</b-th>
                    <b-th>{{ $t("betted_amount") }}</b-th>
                    <b-th>{{ $t("win") }}</b-th>
                    <b-th>{{ $t("net_win") }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    :variant="tr._rowVariant"
                    v-for="(tr, indexTr) in dataPlayer"
                    :key="indexTr"
                  >
                    <b-td class="text-noWrap">{{ tr.Category }}</b-td>
                    <b-td class="text-noWrap">{{ tr.Bets | currency({symbol: ""})  }}</b-td>
                    <b-td class="text-noWrap">{{ tr.Betted | currency({symbol: ""})  }}</b-td>
                    <b-td class="text-noWrap">{{ tr.Won | currency({symbol: ""})  }}</b-td>
                    <b-td class="text-noWrap">{{ tr.NetWin | currency({symbol: ""})  }}</b-td>
                  </b-tr>
                  <b-tr v-if='dataPlayer.length < 1'>
                    <b-th colspan="5" class='text-center'>
                      <div class='m-2'>{{ $t('no_data') }}</div>
                    </b-th>
                  </b-tr>
                </b-tbody>
                
                <br/>

                <b-tbody>
                  <b-tr>
                    <b-td>{{ $t('total') }}:</b-td>
                    <b-td
                    >{{ dataTotal.totals.totalBets  }}
                    </b-td>
                    <b-td
                    >{{ dataTotal.totals.totalBetsAmount | currency({symbol: ""})  }}
                    </b-td>
                    <b-td
                    >{{ dataTotal.totals.totalWinsAmount | currency({symbol: ""})  }}
                    </b-td>
                    <b-td
                    >{{ dataTotal.totals.totalNetIncome | currency({symbol: ""})  }}
                    </b-td>
                  </b-tr>

                </b-tbody>

              </b-table-simple>

              <b-table-simple class="mt-2" hover small caption-top responsive>
                <b-thead head-variant="light">
                  <b-th colspan="2">{{$t('token_sale')}}</b-th>
                  <b-th>{{ dataTotal.totals.recharges | currency({symbol: ""})  }} </b-th>
                  <b-th>{{ dataTotal.totals.retirement | currency({symbol: ""})  }} </b-th>
                  <b-th>{{ dataTotal.totals.diff | currency({symbol: ""})  }} </b-th>
                </b-thead>
              </b-table-simple>

          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTfoot,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import userStoreModule from "../../../apps/user/userStoreModule";
// import clientStoreModule from "../../../pages/clients/clientStoreModule";
import reportAgentsStoreModule from "../reportAgentsStoreModule";
import whitelabelStoreModule from "../../../pages/whitelabel/whitelabelStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import { axiosErrorHandle } from '@core/utils/errorHandler'
import {translatableText} from "@core/utils/utils";
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";

export default {
  components: {
      ExportButton,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
  },
  data() {
    return {
      fields: ["Category", "Bets", "Won", "NetWin"],
      totalPlayers: null,
      dateFromP: moment().format("YYYY-MM-DD:00:00:00"),
      dateToP: moment().format("YYYY-MM-DD:23:59:59"),
      minDate: moment("00:00", "h:mm").format("YYYY-MM-DD HH:MM"),
      maxDate: moment("23:59", "h:mm").format("YYYY-MM-DD HH:MM"),
      currency: "",
      currenciesOptions: [],
      initSelected: [],
      dataGamesFullDetails: [],
      dataTable: [],
      rangeDate: "",
      idUserSelect: "",
      detailsDataChecked: true,
      loadindData: false,
      loadindDataBalancePlayer: false,
      userName: "",
      playerSelectId: null,
      dataPlayer: [],
      dataTotal: {
        totals: {
          recharges: 0,
          retirement: 0,
          diff: 0,
          paneles: 0,

          totalBets: 0,
          totalBetsAmount: 0,
          totalCommission: 0,
          totalNetIncome: 0,
          totalPayment: 0,
          totalWins: 0,
          totalWinsAmount: 0,
        },
      },
      timeOut: null,
    };
  },
  watch: {
    dateFromP() {
      this.getPlayerData();
    },
    dateToP() {
      this.getPlayerData();
    },
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const REPORT_AGENT_APP_STORE_MODULE_NAME = "app-report-agent";
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";
    onMounted(()=>{
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
      if (!store.hasModule(REPORT_AGENT_APP_STORE_MODULE_NAME))
        store.registerModule(REPORT_AGENT_APP_STORE_MODULE_NAME, reportAgentsStoreModule);
      if (!store.hasModule(WL_APP_STORE_MODULE_NAME))
        store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
    })
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      if (store.hasModule(REPORT_AGENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(REPORT_AGENT_APP_STORE_MODULE_NAME);
      if (store.hasModule(WL_APP_STORE_MODULE_NAME))
        store.unregisterModule(WL_APP_STORE_MODULE_NAME);
    });

    const currencies = ref([]);
    const clientCurrencies = ref([]);
    const userList = ref([]);
    const previosNode = ref("");
    const previousNodeFlag = ref(false);
    const selection = ref([]);
    const dataGames = ref([]);
    const game = ref([]);
    const isHistorical = ref(false);

    return {
      dataGames,
      game,
      currencies,
      clientCurrencies,
      selection,
      previousNodeFlag,
      previosNode,
      userList,
      isHistorical
    };
  },
  computed: {
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
    exportFilter(){
      return  {
        datein: this.dateFromP,
        dateout: this.dateToP,
        id: this.playerSelectId,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar?._id,
        isHistorical: this.isHistorical
      };
    }
  },
  methods: {
    yearDropdownPlugin,
    async getPlayerData() {
      this.game = [];
      this.dataPlayer = [];
      this.dataGamesFullDetails = [];
      this.validateData();
      try {
        if (this.playerSelectId == "") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alert!",
              icon: "AlertTriangleIcon",
              text: this.$t('select_user'),
              variant: "warning",
            },
          });
          return false;
        }
        this.loadindData = true;
        const payload = {
          datein: this.dateFromP,
          dateout: this.dateToP,
          id: this.playerSelectId,
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          isHistorical: this.isHistorical
        };

        if (this.playerSelectId == "" || this.playerSelectId == null)
          return false;

        const { data } = await store.dispatch(
          "app-report-agent/fetchAgentsByBrandsReport",
          payload
        );

        this.dataTotal.totals = {
          retirement : data.secondLine.debitsAmmount,
          recharges : data.secondLine.creditsAmmount,
          diff : data.secondLine.netIncome,

          totalBets: data.totals.totalBets,
          totalBetsAmount: data.totals.totalBetsAmmount,
          totalCommission: data.totals.totalCommission,
          totalNetIncome: data.totals.totalNetIncome,
          totalPayment: data.totals.totalPayment,
          totalWins: data.totals.totalWins,
          totalWinsAmount: data.totals.totalWinsAmmount,
        }

        this.game = data.principal;
        for (const brand of this.game){
          var gameAux = {
            Category: brand.brandId,
            Bets: brand.bets,
            Betted: brand.betsAmmount,
            Won: brand.winsAmmount,
            NetWin: brand.netIncome,
            _rowVariant: "",
          };
          this.dataGames.push(gameAux);
          this.dataGamesFullDetails.push(gameAux);

          // const productsLength = brand.products.length;
          // for (var i = 0; i < productsLength; i++) {
          //   var gameAux = {
          //     Category: brand.products[i].productId,
          //     Bets: brand.products[i].bets,
          //     Betted: brand.products[i].betsAmmount,
          //     Won: brand.products[i].winsAmount,
          //     NetWin: brand.products[i].netIncome,
          //     _rowVariant: "",
          //   };
          //   this.dataGames.push(gameAux);
          //   this.dataGamesFullDetails.push(gameAux);
          // }
        }
        this.dataPlayer = this.dataGamesFullDetails;
      } catch (error) {
        this.resetData()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            text:  await translatableText({text: axiosErrorHandle(error)}),
            variant: "danger",
          },
        });
      } finally {
        this.loadindData = false;
      }
    },
    resetData(){
      this.dataPlayer = []
      this.dataTotal.totals = {
          retirement : '',
          recharges : '',
          diff : '',
      }
    },
    validateData() {
      if (this.dateToP < this.dateFromP) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: this.$t('review_dates'),
            variant: "danger",
          },
        });
      }
    },
    selectPlayer({ _id }) {
      this.playerSelectId = _id;
      this.getPlayerData();
    },
    onSearch(username, loading) {
      if (username.length) {
        loading(true);

        const whitelabelId = this.$store.state.whitelabelCurrencyNabvar.whitelabel._id;
        const queryParams = { username: username.trim(), whitelabel: whitelabelId };

        if (this.timeOut) clearTimeout(this.timeOut);

        this.timeOut = setTimeout(() => {
          this.search(loading, queryParams, this);
        }, 500);
      }
    },
    search(loading, queryParams, vm) {
      loading(true);
      //console.log(this.userName.trim());.
      store.dispatch("app-user/fetchUsersByName", queryParams).then((response) => {
        this.userList = response.data;
        loading(false);
      });
    },
  },
  created() {
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
  },
  /* /agentReports?datein=2022-05-01 00:00:00&dateout=2022-06-03 23:59:59&id=628e9a3236c6bba324737a8c&currency=USD&whitelabel=628e9a3236c6bba324737aae */
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}

.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>